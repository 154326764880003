import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Наші послуги - Мотошкола ГудОк
			</title>
			<meta name={"description"} content={"Від першої передачі до повного газу - комплексне навчання для кожного мотоцикліста"} />
			<meta property={"og:title"} content={"Наші послуги | Наші послуги - Мотошкола ГудОк"} />
			<meta property={"og:description"} content={"Від першої передачі до повного газу - комплексне навчання для кожного мотоцикліста"} />
			<meta property={"og:image"} content={"https://solarioxa.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://solarioxa.com/img/751463.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://solarioxa.com/img/751463.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://solarioxa.com/img/751463.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://solarioxa.com/img/751463.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://solarioxa.com/img/751463.png"} />
			<meta name={"msapplication-TileImage"} content={"https://solarioxa.com/img/751463.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" quarkly-title="Cards-7">
			<Override slot="SectionContent" width="95%" min-width="95%" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				lg-flex-direction="column"
				grid-gap="24px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="30%"
					lg-width="auto"
					lg-margin="0px 0px 50px 0px"
				>
					<Text
						margin="0px 0px 8px 0px"
						text-align="left"
						color="--dark"
						font="--base"
						letter-spacing="2px"
						text-transform="uppercase"
					>
						Мотошкола ГудОк
					</Text>
					<Text
						margin="0px 0px 70px 0px"
						font="normal 500 42px/1.2 --fontFamily-sans"
						text-align="left"
						md-margin="0px 0px 40px 0px"
						sm-font="normal 600 42px/1.2 --fontFamily-sans"
						color="--darkL1"
					>
						Наші послуги
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="36px 24px"
					lg-grid-template-columns="repeat(2, 1fr)"
					sm-grid-template-columns="1fr"
					md-grid-template-columns="1fr"
					md-grid-gap="36px 24px"
					width="70%"
					lg-width="auto"
				>
					<Box
						min-width="100px"
						min-height="100px"
						border-color="--color-darkD2"
						display="flex"
						flex-direction="column"
						justify-content="space-between"
						border-width={0}
					>
						<Image src="https://solarioxa.com/img/4.jpg" display="block" margin="0px 0px 30px 0px" />
						<Text
							margin="0px 0px 8px 0px"
							color="--dark"
							font="--headline3"
							text-transform="uppercase"
							letter-spacing="2px"
						>
							Індивідуальні навчальні програми
						</Text>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 15px 0px" font="--lead" color="#133957">
							Курс для початківців: Ідеально підходить для початківців, охоплює такі основи, як керування мотоциклом, протоколи безпеки та вступні техніки їзди.
<br/><br/>
Середні навички їзди: Призначений для райдерів з певним досвідом, з акцентом на орієнтуванні на дорозі, захисних стратегіях їзди та вдосконаленні навичок.

							</Text>
						</Box>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width={0}
						border-style="solid"
						border-color="--color-darkD2"
						display="flex"
						flex-direction="column"
						justify-content="space-between"
					>
						<Image src="https://solarioxa.com/img/5.jpg" display="block" margin="0px 0px 30px 0px" />
						<Text
							margin="0px 0px 8px 0px"
							color="--dark"
							font="--headline3"
							text-transform="uppercase"
							letter-spacing="2px"
						>
							Спеціалізоване навчання
						</Text>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 15px 0px" font="--lead" color="#133957">
							Просунуті техніки їзди: Для досвідчених райдерів, пропонуючи тренування високого рівня в таких сферах, як точне маневрування, високошвидкісне керування та вдосконалені навички безпеки.
<br/><br/>
Індивідуальні тренування один на один: персоналізовані тренування, пристосовані до індивідуальних цілей та рівнів майстерності, що пропонують зосереджену увагу з боку досвідчених інструкторів.

							</Text>
						</Box>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						border-width={0}
						border-style="solid"
						border-color="--color-darkD2"
						display="flex"
						flex-direction="column"
					>
						<Image src="https://solarioxa.com/img/6.jpg" display="block" margin="0px 0px 30px 0px" />
						<Text
							margin="0px 0px 8px 0px"
							color="--dark"
							font="--headline3"
							text-transform="uppercase"
							letter-spacing="2px"
						>
							Додаткові послуги
						</Text>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 15px 0px" font="--lead" color="#133957">
							Майстер-класи з технічного обслуговування: Практичні семінари, на яких ви навчитеся основним навичкам технічного обслуговування мотоциклів, щоб ваш транспортний засіб завжди був у найкращому стані.
<br/><br/>
Консультації щодо спорядження та екіпіровки: Поради експертів щодо вибору правильного спорядження та екіпіровки для безпеки та комфорту на дорозі.

							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});